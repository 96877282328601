<template>

  <div style="height: 100%;width: 100%;">
    <div style="position: absolute; top: 5%; left: -10px;transform: rotate(311deg);color: rgba(0, 0, 0, 0.1);">
      {{deviceDetail.region.toUpperCase()}}</div>
    <div style="height: 20%;">
      <h2 style="text-align: center;line-height: 20px;">{{deviceDetail.
        productName
        }}</h2>
      <div style="text-align: center;color: #333333 !important;font-weight: 300;font-size: 12px;height: 20px;">
        <span style="color: #73777a;">设备状态：</span>
        <div v-if="deviceStatus == '在线'" style=" width: 8px;height: 8px;border-radius: 6px;background-color:
          #1e8e3e;display: inline-block;"></div>
        <div v-else style="width: 8px;height: 8px;border-radius: 6px;background-color: #888888;display: inline-block;">
        </div>
        {{deviceStatus}} &nbsp;&nbsp;&nbsp;<span
          style="color: #73777a;">最后上线时间：</span><span>{{lastActivationTime}}</span>
      </div>

      <div
        style="text-align: center;color: #333333 !important;font-weight: 300;height: 20px;font-size: 12px;height: 20px;">
        <span style="color: #73777a;">设备所在地：</span>
        {{deviceDetail.ip}}
        &nbsp;&nbsp;&nbsp;<span style="color: #73777a;">IP地址：</span><span>{{deviceDetail.ipAddress}}</span>
      </div>
      <div style="text-align: center;color: #333333 !important;font-weight: 300;font-size: 12px;height: 20px;">
        <span style="color: #73777a;">创建时间：</span>{{deviceDetail.gmtCreate}}&nbsp;&nbsp;&nbsp;<span
          style="color: #73777a;">激活时间：</span><span>{{
          deviceDetail.gmtActive}}</span>
      </div>
    </div>
    <div style="padding: 0% 5%;height: 85%;overflow: auto;">
      <div style="display: flex;flex-direction: row;flex-wrap: wrap;justify-content: space-between;">

        <a-button style="width: 40%;" type="primary" @click="sendMsg('relay0 off')">通道1开</a-button>
        <a-button style="width: 40%;" type="primary" @click="sendMsg('relay0 on')" danger>通道1关</a-button>

        <h1 style="width: 100%;"></h1>

        <a-button style="width: 40%;" type="primary" @click="sendMsg('relay1 off')">通道2开</a-button>
        <a-button style="width: 40%;" type="primary" @click="sendMsg('relay1 on')" danger>通道2关</a-button>

        <h1 style="width: 100%;"></h1>
        <a-button style="width: 40%;" type="primary" @click="sendMsg('relay2 off')">通道3开</a-button>
        <a-button style="width: 40%;" type="primary" @click="sendMsg('relay2 on')" danger>通道3关</a-button>

        <h1 style="width: 100%;"></h1>
        <a-button style="width: 40%;" type="primary" @click="sendMsg('relay3 off')">通道4开</a-button>
        <a-button style="width: 40%;" type="primary" @click="sendMsg('relay3 on')" danger>通道4关</a-button>

        <h1 style="width: 100%;"></h1>
        <a-button style="width: 40%;" type="primary" @click="sendMsg('relay4 off')">通道5开</a-button>
        <a-button style="width: 40%;" type="primary" @click="sendMsg('relay4 on')" danger>通道5关</a-button>
        <h1 style="width: 100%;"></h1>

        <a-button style="width: 40%;" type="primary" @click="sendMsg('relay5 off')">通道6开</a-button>
        <a-button style="width: 40%;" type="primary" @click="sendMsg('relay5 on')" danger>通道6关</a-button>

        <h1 style="width: 100%;"></h1>

        <a-button style="width: 40%;" type="primary" @click="sendMsg('relay6 off')">通道7开</a-button>
        <a-button style="width: 40%;" type="primary" @click="sendMsg('relay6 on')" danger>通道7关</a-button>
        <h1 style="width: 100%;"></h1>
        <a-button style="width: 40%;" type="primary" @click="sendMsg('relay7 off')">通道8开</a-button>
        <a-button style="width: 40%;" type="primary" @click="sendMsg('relay7 on')" danger>通道8关</a-button>
        <h1 style="width: 100%;"></h1>
        <a-button style="width: 40%;" type="primary" @click="sendMsg('scene0 off')">场景1开</a-button>
        <a-button style="width: 40%;" type="primary" @click="sendMsg('scene0 on')" danger>场景1关</a-button>
        <h1 style="width: 100%;"></h1>
        <a-button style="width: 40%;" type="primary" @click="sendMsg('scene1 off')">场景2开</a-button>
        <a-button style="width: 40%;" type="primary" @click="sendMsg('scene1 on')" danger>场景2关</a-button>
        <h1 style="width: 100%;"></h1>
        <a-button style="width: 40%;" type="primary" @click="sendMsg('scene2 off')">场景3开</a-button>
        <a-button style="width: 40%;" type="primary" @click="sendMsg('scene2 on')" danger>场景3关</a-button>
        <h1 style="width: 100%;"></h1>
        <a-button style="width: 40%;" type="primary" @click="sendMsg('scene3 off')">场景4开</a-button>
        <a-button style="width: 40%;" type="primary" @click="sendMsg('scene3 on')" danger>场景4关</a-button>
        <h1 style="width: 100%;"></h1>
        <a-button style="width: 40%;" type="primary" @click="sendMsg('scene4 off')">场景5开</a-button>
        <a-button style="width: 40%;" type="primary" @click="sendMsg('scene4 on')" danger>场景5关</a-button>
        <h1 style="width: 100%;"></h1>
        <a-button style="width: 40%;" type="primary" @click="sendMsg('scene5 off')">场景6开</a-button>
        <a-button style="width: 40%;" type="primary" @click="sendMsg('scene5 on')" danger>场景6关</a-button>
        <h1 style="width: 100%;"></h1>
        <a-button style="width: 40%;" type="primary" @click="sendMsg('scene6 off')">场景7开</a-button>
        <a-button style="width: 40%;" type="primary" @click="sendMsg('scene6 on')" danger>场景7关</a-button>
        <h1 style="width: 100%;"></h1>
        <a-button style="width: 40%;" type="primary" @click="sendMsg('scene7 off')">场景8开</a-button>
        <a-button style="width: 40%;" type="primary" @click="sendMsg('scene7 on')" danger>场景8关</a-button>
        <h1 style="width: 100%;"></h1>
      </div>
    </div>
  </div>
</template>

<script>

  import { message } from 'ant-design-vue';
  export default {
    data() {
      return {
        deviceStatus: "离线",
        lastActivationTime: '',
        deviceDetail: {}
      };
    },
    mounted() {

    },
    created() {
      this.queryDeviceDetail()
      this.getDeviceStatus()
      setInterval(() => {
        this.getDeviceStatus()
      }, 5000)
    },
    beforeUnmount() {

    },
    methods: {
      sendMsg(msg) {
        message.destroy()
        if (this.deviceStatus != "在线") {
          message.info("设备不在线", 2)
          return
        }

        this.$http.get("/send?deviceName=POWER_CONTROLLOR&productKey=a1BKFVlkMpx&topicFullName=/a1BKFVlkMpx/POWER_CONTROLLOR/user/get&sendMsg=" + msg).then(data => {
          console.log(data)
          message.destroy()
          message.success("发送成功", 1.5)
        })
      },

      queryDeviceDetail() {
        this.$http.get("/queryDeviceDetail?deviceName=POWER_CONTROLLOR&productKey=a1BKFVlkMpx").then(data => {

          if (data) {
            this.deviceDetail = data
            this.getIpInfo(data.ipAddress)

          }
        })
      },

      getIpInfo(ip) {
        this.$http.get("/getIpInfo?ip=" + ip).then(data => {
          if (data) {

            this.deviceDetail.ip = data
          }
        })
      },

      getDeviceStatus() {
        this.$http.get("/status?deviceName=POWER_CONTROLLOR&productKey=a1BKFVlkMpx").then(data => {

          if (!data) {
            return
          }

          switch (data.status) {
            case "ONLINE":
              this.deviceStatus = "在线"
              break;
            case "OFFLINE":
              this.deviceStatus = "离线"
              break;
            case "UNACTIVE":
              this.deviceStatus = "未激活"
              break;
            case "DISABLE":
              this.deviceStatus = "已禁用"
              break;
          }

          if (data.timestamp) {
            this.lastActivationTime = this.formatTimestamp(data.timestamp)
          }

          // message.destroy()
          // message.success("发送成功")
        })
      },

      formatTimestamp(timestamp) {
        const date = new Date(timestamp);
        return this.formatDate(date);
      },

      formatDate(date) {
        console.log(date)
        const pad = (num) => (num < 10 ? '0' + num : num);

        const year = date.getFullYear();
        const month = pad(date.getMonth() + 1); // Months are zero-indexed
        const day = pad(date.getDate());

        const hours = pad(date.getHours());
        const minutes = pad(date.getMinutes());
        const seconds = pad(date.getSeconds());

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      },

    }
  };
</script>

<style>
  html,
  body,
  #app {
    width: 100%;
    height: 100%;
    margin: 0px 0px;
    padding: 0px 0px;
    background-color: rgb(239, 248, 249);
    overflow: hidden;
  }
</style>