// http.js
import axios from 'axios';
import { message } from 'ant-design-vue';

// 创建 axios 实例
const instance = axios.create({
  baseURL: 'https://api.iavcms.com/device/aliyunApi', // 设置API的基础URL
  timeout: 6000, // 请求超时时间
});

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    // var token = localStorage.getItem("token");
    // if(token){
    //     config.headers['UserToken'] = localStorage.getItem("token");
    // }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    if(response.data.code != 200){
      message.destroy()
      message.error(response.data.msg,3);
    }
    return response.data.obj;
  },
  (err) => { 
    var msg;
    if (err && err.response) {
      switch (err.response.status) {
          case 400: msg = '请求错误(400)'; break;
          case 401: msg = '未授权，请重新登录(401)'; break;
          case 403: msg = '拒绝访问(403)'; break;
          case 404: msg = '请求出错(404)'; break;
          case 408: msg = '请求超时(408)'; break;
          case 500: msg = '服务器错误(500)'; break;
          case 501: msg = '服务未实现(501)'; break;
          case 502: msg = '网络错误(502)'; break;
          case 503: msg = '服务不可用(503)'; break;
          case 504: msg = '网络超时(504)'; break;
          case 505: msg = 'HTTP版本不受支持(505)'; break;
          default: msg = `连接出错(${err.response.status})!`;
      }
  } else {
      msg = '连接服务器失败!'
  }
  message.destroy()
    message.error(msg,5);
    // 对响应错误进行处理
    return Promise.reject(err);
  }
);

// 封装常用的HTTP请求方法
export default {
  get(url) {
    return instance.get(url);
  },

  post(url, data) {
    return instance.post(url, data);
  },

  put(url, data) {
    return instance.put(url, data);
  },

  delete(url, params) {
    return instance.delete(url, { params });
  },
};
